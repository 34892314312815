
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
// import Card from '@material-ui/core/Card';
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .auto":{
      backgroundColor:"white"
    }
  },
  auto:{
    width:"30%",
   
  },
  gridrow:{
    display:"inline-block",
    marginLeft:5
  },
  cardview:{
    display:"inline-block",
    width:"350px",
    paddingLeft:"10px",
    marginLeft:"40px"
  },
  gridview:{
    flex:1,
    display:"inline-block",
    marginLeft:10,
  },
  addbtn:{
    backgroundColor:"lightgreen",
    border:"none",
    color:"white",
    fontSize:"16px",
    marginLeft:"10px",
    width:"50px",
    borderRadius:"5px"
  },
  rembtn:{
    backgroundColor:"red",
    border:"none",
    color:"white",
    fontSize:"16px",
    marginLeft:"10px",
    width:"50px",
    borderRadius:"5px"
  },
  subbtn:{
    backgroundColor:"lightgreen",
    border:"none",
    color:"white",
    fontSize:"16px",
    marginLeft:"10px",
   padding:"10px",
    borderRadius:"5px",
    marginTop:"10px"
  },
}));
const operators=["==","!=","&&"]
export default function Form({
  handleChange,
  handleSubmit,
  post,
  inputList,
  database,
  collections,
  collectionattri,
  handleInputChange,
  handleRemoveClick,
  handleAddClick
})

{
  const classes = useStyles();
 
 
  return (
   
    <div className="form-container" style={{marginLeft:"20px"}}>
       <Grid>
      <form>
        <Grid>
          <Grid className={classes.gridview}>
      
          <b><p>DB Name</p></b>
          <Autocomplete
          className={classes.auto}
            // id="combo-box-demo"
            options={database}
            getOptionLabel={(option) => option}
            style={{ width: 350,backgroundColor:"white" }}

            // onChange={(e)=>handleChange(e,"databasename")}
            onChange={(event, value) => handleChange(event, value, "name")}
            renderInput={(params) => (
              <TextField {...params} label="DB Name" variant="outlined" />
            )}
          />
       
        </Grid>
        <Grid className={classes.gridview}>
       
        <b><p>Entity</p></b>
          <Autocomplete
            style={{ width: 450 }}
           className={classes.auto}
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={collections}
            getOptionLabel={(option) => option.name}
            // onChange={(e)=>handleChange(e,"databasename")}
            onChange={(event, value) => handleChange(event, value, "entity")}
            renderInput={(params) => (
              <TextField {...params} label="Entity" variant="outlined" />
            )}
          />
        
        </Grid>
        </Grid>
       <Grid style={{display:"inline-block"}}>
        <div className={classes.filter} style={{display:"inline-block"}} >

       {inputList.map((x, i) => {
        return (
          <div className="box" style={{display:"inline-block"}}>
            <Grid className={classes.gridrow}>
            <b><p>Filter</p></b>
            <Autocomplete
            style={{display:"inline-block"}}
            style={{ width: 300 }}
           
              name="filter"
              options={collectionattri ? collectionattri.reduce((attribData, row) => {
               let keyValue= Object.keys(row).reduce(function(key) {
                  return row[key];
              });
                const atribRows = [];
                row[keyValue].map((value) => {
                  atribRows.push({  
                    type:keyValue,                 
                    name: keyValue+"."+ value.columnName
                  });
                });
                attribData = attribData.concat(atribRows);
                return attribData;
              }, []):""}
              getOptionLabel={(option) => option && option.name}       
              groupBy={(option) => option.type}      
              value={x.filter}
              onChange={(event, value) => handleInputChange(event,"filter", value,i)}
              renderInput={(params) => (
                <TextField {...params} label="filter" variant="outlined" />
              )}
            />
            </Grid>
            <Grid className={classes.gridrow}>
            <b><p>Operator</p></b>
            <Autocomplete
            style={{display:"inline-block"}}
              name="operators"
              options={operators}
              getOptionLabel={(option) => option}
              value={x.operators}
              onChange={(event, value) => handleInputChange(event,"operators", value,i)}
              renderInput={(params) => (
                <TextField {...params} label="operators" variant="outlined" />
              )}
            />
           </Grid>
           <Grid className={classes.gridrow}>
           <b><p>key</p></b>
            <TextField
              style={{display:"inline-block"}}
              style={{ width: 140 }}
              name="key"              
              //onChange={e => setValue(e.target.value)}
              onChange={(event, value) => handleInputChange(event,"key", value,i)}
              value={x.key}
              // onChange={e => handleInputChange(e, i)}
               id="outlined-basic" label="key" variant="outlined" />

            <div className="btn-box" style={{display:"inline-block"}}>
              {inputList.length !== 1 && <button className={classes.rembtn}
                onClick={() => handleRemoveClick(i)}>-</button>}
              {inputList.length - 1 === i && <button className={classes.addbtn}  onClick={handleAddClick}>+</button>}
            </div>
            </Grid>
            <Grid className={classes.gridrow} style={{display:"flex-end"}}>
            {/* <Card  className={classes.cardview} > */}
              <div style={{display:"inline-block"}}>
            <p>key: {x.key ? x.key : ""}</p>
            </div>
            <div style={{display:"inline-block"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label>
             Value:
             <TextField id="outlined-basic" label="value" variant="outlined" value={x.value} onChange={(event, value) => handleInputChange(event,"value", value,i)}/>
            </label>
            </div>
            {/* </Card> */}
            </Grid>
          </div>
        );
      })}
 
       </div>
       
  </Grid>
        <br />

        <button className={classes.subbtn} onClick={handleSubmit}>
          Submit
        </button>
      </form>
      </Grid>
    </div>
  );
}
