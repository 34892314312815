import React from 'react';

import * as WebDataRocksReact from './webdatarocks.react';
//import "webdatarocks/webdatarocks.highcharts";
var dynamicpassData = [];

function getJSONData(data) {
  return data
}

function customizeToolbar(toolbar) {
  var tabs = toolbar.getTabs(); // get all tabs from the toolbar
  toolbar.getTabs = function () {
    delete tabs[0];
    delete tabs[1];
    // delete tabs[2];
    delete tabs[4]; // delete the first tab
    return tabs;
  }
}


function customizeCellFunction(cell, data) {
  

  //   cellclick: function(cell) {
  //     alert("Click on cell - row: " +
  //         cell.rowIndex + ", column: " +
  //         cell.columnIndex +
  //         ", label: " +
  //         cell.label);
  // }
  if (data && data.type == "value") {
    if (data.label.includes(":::")) {
      cell.addClass("editable");
      cell.text = `<button ('${data.label}')>Approve</button>`
    }
    else {
      cell.addClass("editable");
      cell.text = `<div contenteditable="false" change="onChangeCell(e)">${data.label}</div>`;
    }

    // cell.text = `<input type="text" value="${data.label}" onchange="onChangeCell(e)" data-cell='${JSON.stringify(data)}'>`;
  }

}

function myFunction(e) {


  var currentdata = dynamicpassData[0].filter(student => student.Action == e.label);
  console.log(currentdata);
  // alert(currentdata)

}
function selectalldata(e) {
 
  console.log(e)

}


// pivot.customizeCell(function(cell, data) {
//   if (data && data.type == "value") {
//     cell.addClass("editable");
//     cell.text = `<input type="text" value="${data.label}" onchange="onChange(event)" data-cell='${JSON.stringify(data)}'>`;
//   }
// }) 

const onChangeCell = (e) => {

  let modDataObj = {};
  let newValueStr = e.target.value.replace(/\s/g, '');
  modDataObj.newValue = parseInt(newValueStr, 10);

  modDataObj.cell = JSON.parse(e.target.getAttribute("data-cell"));
  modDataObj.oldValue = modDataObj.cell.value;

  modDataObj.delta = modDataObj.newValue - modDataObj.oldValue;

  console.log("cell:", modDataObj.cell);
  console.log("old value:", modDataObj.oldValue);
  console.log("new value:", modDataObj.newValue);
  console.log("delta:", modDataObj.delta);

  // $.ajax({
  //   type: "POST",
  //   url: 'url-to-the-data-to',
  //   data: JSON.stringify(modDataObj),
  //   dataType: "json",
  //   success: function(data) {
  //     console.log("Data was successfully sent.");
  //   },
  //   error: function(data) {
  //     console.log("Error sending the data.");
  //   }
  // });

}

export class WebDataRocks extends React.Component {

  myRef = null;

  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.onChangeCell = this.onChangeCell.bind(this)
    this.myFunction = this.myFunction.bind(this)
    dynamicpassData = [];
    dynamicpassData.push(this.props.passData);

  }

  onChangeCell(e) {
    
  }

  myFunction(e) {
    
  }



  render() {
    return (

      <div>
        <WebDataRocksReact.Pivot
          ref={(elem) => {
            this.myRef = elem
          }}

          customizeCell={customizeCellFunction}
          beforetoolbarcreated={customizeToolbar}
          toolbar={true}
          // report= {{
          //   dataSource:{
          //     filename:'https://jsonplaceholder.typicode.com/posts'
          //   }
          // }}
          cellclick={(e) => myFunction(e)}
          // getSelectedCell={(e) => selectalldata(e)}
          // onClick ={(e) => myFunction(e)}
          report={
            {

              "dataSource": {
                "dataSourceType": "json",
                "data": getJSONData(this.props.passData)

              },
              "slice": {
                "expands": {
                  "expandAll": true
                }
              },
              "options": {
                "grid": {
                  "type": "flat",
                  "showGrandTotals": "off",
                  "showTotals": "off"
                }
              },
            }
          }
        />
      </div>
    );
  }
}

export default WebDataRocks;
