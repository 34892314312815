import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
   margin:40,
   
   "& .MuiPaper-elevation1":{
       boxShadow:"1px 2px 1px -1px rgb(0 0 0 / 63%), 0px 0px 1px 0px rgb(0 0 0 / 66%), 0px 1px 3px 0px rgb(0 0 0 / 60%)",
       width:"90%",
       marginTop:10
   },
   "& .MuiAccordion-root.Mui-expanded:first-child":{
       marginTop:10
   }
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  gridacc:{
      display:"contents",
      paddingTop:"10px",
  },
  gridform:{
      paddingRight:"10px"
  },
  addentity:{
    marginLeft:"10px",
    height:42,
    marginTop:"5px"
  },
  rembtn:{
      paddingTop:15,
      paddingLeft:10
  }
}));

export default function Main({}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [currentValue, setValue] = useState("");
  const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
  const [inputAccr, setInputAccr] = useState([{ firstName: "", lastName: "" }]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

 

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: "", lastName: "" }]);
  };

  const handleRemoveClickaccr = index => {
    const listacc = [...inputAccr];
    listacc.splice(index, 1);
    setInputAccr(listacc);
  };

  // handle click event of the Add button
  const handleAddClickaccr = () => {
    setInputAccr([...inputAccr, { firstName: "", lastName: "" }]);
  };
  return (
    <div className={classes.root}>
        <Grid style={{display:"flex"}}>
        <Autocomplete
          className={classes.auto}
            id="combo-box-demo"
            // options={database}
            getOptionLabel={(option) => option}
            style={{ width: 350 }}
            // onChange={(e)=>handleChange(e,"databasename")}
            // onChange={(event, value) => handleChangedb(event, value, "name")}
            renderInput={(params) => (
              <TextField {...params} label="DB Name" variant="outlined" />
            )}
          />
          <Button variant="contained" color="primary" className={classes.addentity} onClick={handleAddClick}>
           Add Entity
          </Button>
        </Grid>
        
         {inputList.map((x, i) => {
             
            return( 
        <div style={{display:"flex"}}>
            
           
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <Grid>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
            <Grid>
          <Autocomplete
            className={classes.gridform}
            style={{ width: 220 }}
            id="combo-box-demo"
            // options={collections}
            getOptionLabel={(option) => option.name}
            // onChange={(event, value) => handleChange(event, value, "entity")}
            renderInput={(params) => (
              <TextField {...params} label="Entity"  />
            )}
          />
          </Grid>
              
        </AccordionSummary>
        </Grid>
        <Divider/>
        
             
            
        <AccordionDetails>
       
        <div  className={classes.gridacc} >
          <div>
            {inputAccr.map((x, j) => {
             
             return(
          <div style={{display:"flex"}}>
               
           <Autocomplete
           style={{display:"flex"}}
            className={classes.gridform}
            style={{ width: 220 }}
            id="combo-box-demo"
            // options={collections}
            getOptionLabel={(option) => option.name}
            // onChange={(event, value) => handleChange(event, value, "entity")}
            renderInput={(params) => (
              <TextField {...params} label="Filter" />
            )}
          />
           <Autocomplete
            style={{display:"flex"}}
            className={classes.gridform}
            style={{ width: 100 }}
            id="combo-box-demo"
            // options={collections}
            getOptionLabel={(option) => option.name}
            // onChange={(event, value) => handleChange(event, value, "entity")}
            renderInput={(params) => (
              <TextField {...params} label="operator"  />
            )}
          />
            <TextField
             style={{display:"flex"}}
              style={{ width: 100 }}
              name="key"              
              //onChange={e => setValue(e.target.value)}
            //   onChange={(event, value) => handleInputChange(event,"key", value,i)}
            //   value={x.key}
              onChange={e => setValue(e.target.value)}
              value={currentValue}
              // onChange={e => handleInputChange(e, i)}
               id="outlined-basic" label="key" 
                />
                 <div className="btn-box">
              {inputAccr.length !== 1 && <Button
                className="mr10"
                onClick={() => handleRemoveClickaccr(j)}><DeleteIcon/></Button>}
              {inputAccr.length - 1 === j && <Button onClick={handleAddClickaccr}><AddIcon/></Button>}
            </div>
           
            </div>
             );
            })}
            </div>

            <div style={{display:"flex"}} >
            <table style={{width:"70%",paddingLeft:"100px"}}>
            <tr>
                    <th>key</th>
                    <th>value</th>
                </tr>
                {inputAccr.map((x, j) => {
             
             return( 
                <tr>
                    <td>{currentValue ? currentValue : "Key"}</td>
                    <td><TextField type="text"/></td>
                </tr>
                  );
                })}
                </table>
                </div>
          
            </div>
          
        </AccordionDetails>
      
      
         
                
      </Accordion>
      <Grid >
               <div className={classes.rembtn}>
  
                {inputList.length !== 1 &&
                <div> 
                    <Button color="secondary" 
                  onClick={() => handleRemoveClick(i)}><DeleteIcon/>
                  </Button>
                  </div>
                  }
              
              </div> 
              </Grid>
    
      </div>
      );
    })}
    </div>
  );
}
