import React, { Component } from "react";
import Form from "./Form";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

let entityData;
let finalFilter="";
export default class ContentView extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      post: {
        name: "",
        entity: "",
        filter: "",
      },
      data: [],
      database: [],
      collections: [],
      collectionattri: [],
      inputList:[{ filter: "", operators: "",key:"",value:"" }],
      finalReport:{}

    };
  }
 
  componentDidMount() {
    fetch("http://164.52.210.54:8080/api/databaseInfo?type=database-root")
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState({
            database: res.Result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  //entitiy
  getentity = (data) => {
    fetch("http://164.52.210.54:8080/api/getcollectionlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        db_name: data,
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState({
            collections: res.Result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };
  //filter
  getfilter = (data) => {
    fetch("http://164.52.210.54:8080/api/getcollectionattributes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        db_name: this.state.post.name,
        entity: data,
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          this.setState({
            collectionattri: res.Result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  handleChange = (e, namedata, fieldname) => {
    const { name, value } = e.target;
    if (fieldname == "name") {
      this.getentity(namedata);
      this.setState({
        post: { entity: "", filter: "" },
      });
    } else if (fieldname == "entity") {
      entityData = namedata.map(function (item) {
        return item.name            
      });
      this.getfilter(entityData);
    }
    this.setState((prevState) => ({
      post: { ...prevState.post, [fieldname]: namedata },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    finalFilter=""
    this.state.inputList && this.state.inputList.map((data,i)=>{
      if(i === this.state.inputList.length-1){
        return finalFilter= finalFilter + data.filter.name+data.operators+data.value
      }else if(this.state.inputList.length>1 ){
        return finalFilter= finalFilter + data.filter.name+data.operators+data.value +" && "
      } else {
        return finalFilter= finalFilter + data.filter.name+data.operators+data.value
      }
     
    })    
    console.log(finalFilter)
    if(finalFilter!="undefined"){
      this.state.post["filter"]=finalFilter
    }
  
    this.setState((prevState) => ({      
      finalReport:{db_name:prevState.post.name,entity:entityData && entityData.toString(),filter:prevState.post.filter!=undefined ? prevState.post.filter:""},
      data: [...prevState.data, prevState.post],
     // post: { name: "", entity: "", filter: "" },
    }));   
  };
 

  // handle input change
  handleInputChange = (e, name,value,index) => {
    const list = [...this.state.inputList];
    if(name=="key" || name=="value"){
      list[index][name] = e.target.value;
    }else{
      list[index][name] = value;
    }
    console.log(list);
    this.setState({
      inputList: list,
    });
  };

  // handle click event of the Remove button
  handleRemoveClick = index => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({inputList: list,
    });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({
      inputList: [...this.state.inputList, { filter: "", operators: "",key:"" }],
    });
  };
  render() {
    return (
      <div className="App">
        <Form
          handleChange={this.handleChange}
          post={this.state.post}
          handleSubmit={this.handleSubmit}
          database={this.state.database}
          collections={this.state.collections}
          collectionattri={this.state.collectionattri}
          handleAddClick={this.handleAddClick}
          handleRemoveClick={this.handleRemoveClick}
          handleInputChange={this.handleInputChange}
          inputList={this.state.inputList}
        />
        <div className="post-container">
          <div>
           { JSON.stringify(this.state.finalReport)}
            {/* {this.state.data.map((item, index) => (
              <div key={index}>
                <Card >
                  <CardContent>
                    <p>
                      &#123;
                      <br />
                      "db_name":"{item.name}",
                      <br />
                      "entity":{entityData},
                      <br />
                      "filter":"{item.filter}"<br />
                      &#125;
                    </p>
                  </CardContent>
                </Card>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    );
  }
}
