import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TreeView from "@material-ui/lab/TreeView";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Checkbox } from "@material-ui/core";
import JSONPretty from 'react-json-pretty';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const useViewStyles = makeStyles({
  root: {},

});

const useItemStyles = makeStyles(theme => ({
  root: {
    "& > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
      background: "transparent !important",
      pointerEvents: "none"
    },

  },

  iconContainer: {
    marginRight: 12,
    "& > svg": {
      padding: 8,
      "&:hover": {
        opacity: 0.6
      }
    }
  },

  label: {
    padding: 0
  },
  selected: {
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      background: theme.palette.primary.main,
      border: "1px solid transparent"
    }
  }
}));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  auto: {
    width: "30%"
  },
  gridrow: {
    display: "inline-block",
    marginLeft: 5
  },
  treecard: {
    height: 500,
    overflowY: "scroll"
  },
  cardview: {
    display: "inline-block",
    width: "350px",
    paddingLeft: "10px",
    marginLeft: "40px"
  },
  gridview: {
    // flex: 1,
    display: "inline-block",
    marginLeft: 10,
  },
  btn: {
    marginTop: 19,
    backgroundColor: "#3f51b5",
    color: "white",
  },

}));
let entityData;
let attribList = [];
let attribInsideList = [];
let concatAttrib = {};
let finalAttrib;
const url=`${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_ENTITY_DOMAIN}:${process.env.REACT_APP_APIPORT}`;
export default function TreeViews() {
  useEffect(() => {
    fetch(`${url}/api/databaseInfo?type=database-root`)
      .then((res) => res.json())
      .then(
        (res) => {
          setDataBase(res.Result)
        },
        (error) => {
        }
      );
    fetch(`${url}/api/read_documents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        "db_name": "QDM_Metadata",
        "entity": "QDMOperators",
        "return_fields": "QDMOperators"
      }) 

    })
      .then((res) => res.json())
      .then(
        (res) => {
          setOperators(res.result)
        },
        (error) => {
        }
      );
  }, []);
  const classes = useStyles();
  const classesView = useViewStyles();
  const classesItem = useItemStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [database, setDataBase] = useState([]);
  const [operators, setOperators] = useState([]);
  const [collections, setCollections] = useState({ entityList: [], filterList: [] });
  const [output, setOutput] = useState({ attributes: [], collection: '', where: {}, include: [] });
  const [checkData, setCheckData] = useState({})
  const [filterCheckData, setFilterCheckData] = useState({})
  const [filterTextData, setFilterTextData] = useState({})
  const [data, setData] = useState({});
  const handleDBChange = (e, namedata, fieldname) => {
    if (fieldname == "name") {
      if (namedata != null) {
        getentity(namedata);
        data[fieldname] = namedata;
      } else {
        setCollections({
          filterList: [],
          entityList: []
        })
        setOutput({
          collection: [],
          where: {},
          attributes: [],
          include: []
        })
      }
    }
  };
  const getentity = (dbname) => {
    fetch(`${url}/api/getcollectionlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        db_name: dbname,
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          getfilter(res.Result);
        },
        (error) => {

        }
      );
  };
  const getfilter = (entity) => {
    entityData = entity.map(function (item) {
      return item.name
    });
    fetch(`${url}/api/getcollectionattributes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        db_name: data.name,
        entity: entityData,
      }),
    })
      .then((res) => res.json())
      .then(
        (res) => {
          const result = res.Result
          let flterData = []
          result && result.map((row) => {
            let keyValue = Object.keys(row).reduce(function (key) {
              return row[key];
            });
            const atribRows = [];
            row[keyValue].map((value, j) => {
              atribRows.push({
                id: Math.random(),
                entity: keyValue,
                name: value.columnName,
                type: value.columnType,
                LOVType: value.LOVType,
                LOV_key_field: value.LOV_key_field,
                LOV_ref_collection: value.LOV_ref_collection,
                LOV_value_field: value.LOV_value_field,
              });
            });


            flterData = flterData.concat(atribRows);
            setCollections({
              filterList: flterData,
              entityList: entity
            })


          })
        },
        (error) => { }
      );
  };
  const executeQuery = () => {
    navigator.clipboard.writeText(JSON.stringify(output))
    fetch(`http://164.52.212.67:4000/${output}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },

    })
      .then((res) => res.json())
      .then(
        (res) => {
          console.log(res)

          // { res.length != 0 ? setPassData(res) : setPassData() }

          // getfilter(res.Result);
          // getfilter(event.target.innerText);
        },
        (error) => {

        }
      );
  };
  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    console.log(event.target.innerText)
    setExpanded(nodeIds);
  };
  const handleTreeSelect = (event, treeItemPosition, type) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    if (treeItemPosition === "parent") {
      if (event.target.innerText != undefined) {
        if (output.collection === event.target.innerText) {
          setOutput({
            collection: event.target.innerText,
            where: output.where,
            attributes: output.attributes,
            include: output.include
          })
        } else {
          setOutput({
            collection: event.target.innerText,
            where: {},
            attributes: [],
            include: []
          })
          if (checkData !== undefined && checkData !== false) {
            setCheckData(false)
          }
        }
      }
    }
  };
  const handleFilters = (event, name) => {
    console.log(event.target.name)
    // if (event.target.checked == true) {
    //   setOutput({ where: {...output.where, [name]: event.target.name}, collection: output.collection, attributes: output.attributes })
    // } 
    // else {
    //   setOutput({...where, where: delete output.where[event.target.name], collection: output.collection, attributes: output.attributes })
    // }
    setFilterCheckData({ ...filterCheckData, [event.target.name]: event.target.checked })
  }
  const handleInputChange = (e, name, operator, id, parentIndex, type, record) => {
    setFilterTextData({ ...filterTextData, [name + operator + id + parentIndex]: e.target.value })
    let value = e.target.value
    if (type == 'string') {
      value = `'${e.target.value}'`
    } else if (type == 'number') {
      value = e.target.value
    }
    if (record != undefined) {
      setOutput({
        where: {
          ...output.where, [record.LOV_ref_collection]: {
            [name]: operator + " " + value
          }
        }, collection: output.collection, attributes: output.attributes,
        include: [{
          'as': record.LOV_ref_collection, 'model': record.LOV_ref_collection, 'sourcekey': record.name,
          'destinationkey': record.LOV_key_field,
        }]
      })
    } else {
      setOutput({ where: { ...output.where, [name]: operator + " " + value }, collection: output.collection, attributes: output.attributes, include: output.include })
    }
  };
  const handleNodes = (event, entityname) => {

    let name = event.target.name.match(/[a-zA-Z]+/g)
    if (event.target.checked == true) {
      if (entityname != undefined) {
        attribInsideList.push(name[0])
        concatAttrib = { [entityname]: attribInsideList }
        finalAttrib = attribList.concat(concatAttrib)
        setOutput({ attributes: output.attributes = finalAttrib, collection: output.collection, where: output.where, include: output.include })
      } else {
        attribList.push(name[0])
        finalAttrib = attribList.concat(concatAttrib)
        setOutput({ attributes: [...output.attributes, name[0]], collection: output.collection, where: output.where, include: output.include })
      }
    } else {
      if (entityname != undefined) {
        attribInsideList.pop(name[0])
        concatAttrib = { [entityname]: attribInsideList }
        if (attribInsideList.length != 0) {
          finalAttrib = attribList.concat(concatAttrib)
        } else {
          finalAttrib = attribList;
        }
        setOutput({ attributes: output.attributes = finalAttrib, collection: output.collection, where: output.where, include: output.include })
      } else {
        attribList.pop(name[0])
        finalAttrib = attribList.concat(concatAttrib)
        setOutput({ attributes: output.attributes.filter((i) => (i !== name[0])), collection: output.collection, where: output.where, include: output.include })
      }
    }
    setCheckData({ ...checkData, [event.target.name]: event.target.checked })
  };
  return (

    <>

      <Grid>
        <Grid style={{ justifyContent: "space-between", display: "flex" }}>
          <Grid item xs={6}>
            <Autocomplete
              className={classes.auto}
              id="combo-box-demo"
              options={database}
              getOptionLabel={(option) => option}
              style={{ width: 350 }}
              onChange={(event, value) => handleDBChange(event, value, "name")}
              renderInput={(params) => (
                <TextField {...params} label="DB Name" variant="outlined" style={{ backgroundColor: "white" }} />
              )}
            />
          </Grid>
          {collections.filterList.length != 0 &&
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                className={classes.btn}
                disableElevation
                onClick={executeQuery}
              >
                Execute
              </Button>
            </Grid>
          }
        </Grid>
        {collections.filterList.length != 0 &&
          <Grid container style={{ marginTop: 15 }}>
            <Grid item xs={6}>
              <Card className={classes.treecard}>
                <CardContent>
                  <TreeView
                    classes={classesView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    // onNodeSelect={handleSelect}
                    multiSelect
                  >
                    {collections.entityList && collections.entityList.map((a, aa) => {
                      let filterNodeID = aa + "1"
                      let nodeNodeID = aa + "2";
                      return (
                        <TreeItem classes={classesItem} key={a._id} nodeId={a._id} name={a.name} label={a.name} onClick={(e) => handleTreeSelect(e, "parent")}>
                          <TreeItem nodeId={filterNodeID} label="Filters">
                            {collections.filterList && collections.filterList.map((b, bb) => {
                              if (a.name == b.entity) {
                                return (
                                  <div key={b.id}>
                                    {
                                      b.LOV_ref_collection != undefined &&
                                      <TreeItem classes={classesItem} style={{ marginLeft: 10 }} key={b.id + bb} nodeId={`"${b.id + bb + filterNodeID}"`} name={b.name} label={b.LOV_ref_collection} onClick={(e) => handleTreeSelect(e, "refentity", "filters")}>
                                        {collections.filterList && collections.filterList.map((c, cc) => {
                                          if (b.LOV_ref_collection == c.entity) {
                                            return (<div style={{ display: "flex", alignItems: "center", marginBottom: 8, marginTop: -10 }} key={c.id}>

                                              <TreeItem classes={classesItem} key={c.id} nodeId={`"${c.id + cc}"`} name={c.name} label={c.name} >
                                                {
                                                  operators.map((d, dd) => {
                                                    let opNodeId = dd + "5"
                                                    return (
                                                      <div key={dd} style={{ display: "flex", alignItems: "center", marginBottom: -15, marginTop: -10 }}>
                                                        <Checkbox
                                                          name={c.name + d.name + opNodeId + bb}
                                                          onChange={($event) => handleFilters($event, c.name)}
                                                          checked={filterCheckData[c.name + d.name + opNodeId + bb] || false}
                                                        />
                                                        <span style={{ marginLeft: -30 }}><TreeItem classes={classesItem} key={dd} nodeId={opNodeId} label={d.name} />
                                                        </span>
                                                        {filterCheckData[c.name + d.name + opNodeId + bb] == true && <span style={{ marginLeft: 10, marginTop: -8 }}><TextField id="outlined-basic" value={filterTextData[c.name + d.operator + opNodeId + bb] || ""} name={filterTextData[c.name + d.operator + opNodeId + bb] || "textbox"} onChange={(event, value) => handleInputChange(event, c.name, d.operator, opNodeId, bb, b.type, b)} /></span>}
                                                      </div>
                                                    )
                                                  })
                                                }
                                              </TreeItem>
                                            </div>)
                                          }

                                        })}
                                      </TreeItem>
                                    }
                                    <TreeItem classes={classesItem} key={a.name + b.name + b.id} nodeId={`"${b.id}"`} name={b.name} label={b.name} >
                                      {operators &&
                                        operators.map((e, ee) => {
                                          let opNodeId = ee + "6"
                                          return (
                                            <div key={a.name + b.name + b.id + ee} style={{ display: "flex", alignItems: "center", marginBottom: -15, marginTop: -10 }}>
                                              <Checkbox
                                                name={b.name + e.name + opNodeId + bb}
                                                onChange={($event) => handleFilters($event, b.name)}
                                                checked={filterCheckData[b.name + e.name + opNodeId + bb] || false}
                                              />
                                              <span style={{ marginLeft: -30 }}><TreeItem classes={classesItem} nodeId={opNodeId} label={e.name} />
                                              </span>
                                              {filterCheckData[b.name + e.name + opNodeId + bb] == true && <span style={{ marginLeft: 10, marginTop: -8 }}><TextField id="outlined-basic" value={filterTextData[b.name + e.operator + opNodeId + bb] || ""} name={filterTextData[b.name + e.operator + opNodeId + bb] || "textbox"} onChange={(event, value) => handleInputChange(event, b.name, e.operator, opNodeId, bb, b.type)} /></span>}
                                            </div>
                                          )
                                        })
                                      }


                                    </TreeItem>
                                  </div>
                                )
                              }
                            })}
                          </TreeItem>
                          <TreeItem nodeId={nodeNodeID} label="Nodes">
                            {collections.filterList && collections.filterList.map((f, ff) => {
                              let subNodeId = ff + "4"
                              if (a.name == f.entity) {
                                return (
                                  <div key={f.id}>
                                    {
                                      f.LOV_ref_collection != undefined && <
                                        TreeItem classes={classesItem} style={{ marginLeft: 10 }} nodeId={`"${f.id + ff + nodeNodeID}"`} name={f.name} label={f.LOV_ref_collection} onClick={(e) => handleTreeSelect(e, "refentity", "nodes")}>
                                        {collections.filterList && collections.filterList.map((g, gg) => {
                                          let refField = gg + "3"
                                          if (f.LOV_ref_collection == g.entity) {
                                            return (<div style={{ display: "flex", alignItems: "center", marginBottom: -10, marginTop: -10 }} key={g.id}>
                                              <Checkbox
                                                name={g.name + gg + refField}
                                                onChange={(event, value) => handleNodes(event, f.LOV_ref_collection)}
                                                checked={checkData[g.name + gg + refField] || false}
                                              />
                                              <span style={{ marginLeft: -30 }}><TreeItem classes={classesItem} nodeId={refField} name={g.name} label={g.name} ></TreeItem></span>
                                            </div>)

                                          }

                                        })}
                                      </TreeItem>
                                    }
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: -10, marginTop: -10 }}>
                                      <Checkbox
                                        name={f.name + ff + subNodeId}
                                        onChange={(event) => handleNodes(event)}
                                        checked={checkData[f.name + ff + subNodeId] || false}
                                      />
                                      <span style={{ marginLeft: -30 }}><TreeItem classes={classesItem} nodeId={subNodeId} name={f.name} label={f.name} ></TreeItem></span>
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </TreeItem>
                        </TreeItem>
                      )
                    })}
                  </TreeView>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.treecard}>
                <CardContent>
                  <div style={{ textAlign: "right", cursor: "pointer" }}>
                    <FileCopyIcon />
                  </div>
                  <JSONPretty id="json-pretty" data={output}></JSONPretty>
                </CardContent>
              </Card>
            </Grid>
          </Grid>}
      </Grid>
    </>

  )
}
