import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WebDataRocks from "./webDataRocks";
import { TableCell } from "@material-ui/core";

import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JSONPretty from "react-json-pretty";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
   
  },

  gridbtn: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  btn: {
    marginTop: 30,
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#001691",
    },
  },
  bar: {
    backgroundColor: "white",
    color: "black",
    width: "28%",
    borderRadius: "5px",
    marginLeft: "7px",
    marginTop: "5px",
    boxShadow: "none",
   
  },
  jsoncard: {
    height: 500,
    overflowY: "scroll",
  },
  indicator: {
    backgroundColor: "red",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));
function TabPanel(props) {
  const { children, Tabval, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Tabval !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {Tabval === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const url = `${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_ENTITY_DOMAIN}:${process.env.REACT_APP_APIPORT}`;

export default function Aql() {
  useEffect(() => {
    fetch(`${url}/api/databaseInfo?type=database-root`)
      .then((res) => res.json())
      .then(
        (res) => {
          setDataBase(res.Result);
        },
        (error) => {}
      );
  }, []);
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [data, setData] = React.useState([]);
  const [database, setDataBase] = React.useState([]);
  const [passData, setPassData] = useState();
  const [Tabval, setTabval] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabval(newValue);
  };
  const handleTextChange = (event) => {
    if (event.target.value === "") {
      setPassData(null);
    }
    setValue(event.target.value);
  };

  
  const handleChange = (e, namedata, fieldname) => {
    

    if (fieldname == "name") {
      data[fieldname] = namedata;
      setPassData(null);
      setValue("");
    }
  };

  const executeQuery = () => {
  
    if (data["name"] !== undefined && value !== "") {
      fetch(`${url}/api/execute_aql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          db_name: data.name,
          query: value,
        }),
      })
        .then((res) => res.json())
        .then(
          (res) => {
            console.log(res);
            for(var i=0;i<res.length; i++)
            {
            //  onClick={() => handleClickOpenDeleteSchedule(x.RefineDataSourceName, x.RefineDatasourceID) }

            // onClick={(event) => selectRow(event)}
              res[i]["Action"]= res[i]._key +":::";
            }
          
            {
              res.length != 0 ? setPassData(res) :  toast("No data found") && setPassData();
            }

            // getfilter(res.Result);
            // getfilter(event.target.innerText);
          },
          (error) => {}
        );
    } else {
    
      if (data["name"] !== undefined) {
        if (value === "") {
          toast("Enter Query");
        }
        
      } else {
        toast("Enter DB Name");
      }
    }
  };
  return (
    <div className={classes.root}>
      <ToastContainer autoClose={3000} />
      <Grid className={classes.gridbtn}>
        <Grid>
          <Autocomplete
            className={classes.auto}
            id="combo-box-demo"
            options={database}
            getOptionLabel={(option) => option}
            style={{ width: 350 }}
            // onChange={(e)=>handleChange(e,"databasename")}
            onChange={(event, value) => handleChange(event, value, "name")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="DB Name"
                variant="outlined"
                style={{ backgroundColor: "white" }}
              />
            )}
          />
        </Grid>

        <Grid>
          <Button
            variant="contained"
            className={classes.btn}
            disableElevation
            onClick={executeQuery}
          >
            Execute
          </Button>
        </Grid>
      </Grid>

      <Grid>
        <TextField
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            borderBottom: "none",
          }}
          id="filled-multiline-static"
          label="Query"
          multiline
          rows={6}
          defaultValue="Default Value"
          variant="outlined"
          value={value}
          onChange={handleTextChange}
          InputProps={{ classes }}
        />
      </Grid>

      <Grid>
        {passData && (
          <AppBar position="static" className={classes.bar}>
            <Tabs
              Tabval={Tabval}
              onChange={handleChangeTab}
              aria-label="tabs"
             
               classes={{
                indicator: classes.indicator
              }}

             >
              <Tab label="Table" {...a11yProps(0)} />
              <Tab label="JSON" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        )}
        <TabPanel Tabval={Tabval} index={0}>
          {passData && <WebDataRocks passData={passData} />}
        </TabPanel>
        <TabPanel Tabval={Tabval} index={1}>
          {passData && (
            <Card className={classes.jsoncard}>
              <CardContent>
                <div style={{ textAlign: "right", cursor: "pointer" }}>
                  {/* <FileCopyIcon /> */}
                </div>
                <JSONPretty
                  id="json-pretty"
                  data={passData && JSON.stringify(passData)}
                ></JSONPretty>
                {/* {passData && JSON.stringify(passData)} */}
              </CardContent>
            </Card>
          )}
        </TabPanel>
      </Grid>
      {/* <Grid style={{paddingLeft:10}}>
      {passData && <WebDataRocks passData={passData} />}
      </Grid>
      <Grid>
        {passData && JSON.stringify(passData)}
      </Grid> */}
    </div>
  );
}
