import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, AppBar, Typography, Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiAppBar-colorPrimary":{
        backgroundColor:"#3f51b5",
        color:"#007aff",
        height:"64px",
        justifyContent:"center",
        alignItems:"center",
        boxShadow:"0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)",
    },
    "& .MuiToolbar-regular":{
      minHeight:"44px"
    }
  },
  button: {
    backgroundColor: "white",
    padding: 14,
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "none",
    fontWeight: 500,
  },
  qdm:{
    color:"white",
    fontWeight:"600",
    alignItems:"center",
    textAlign:"center"
  }
}));

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              container
              justify="center"
              alignItems="center"
            >
              <Typography variant="h5" className={classes.qdm}>
                AINQA 
              </Typography>
            </Grid>
         
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
